import { SuperVue } from '@/mixins/SuperVue'
import { Options, Vue } from 'vue-class-component'
import Utils from '@/utils'
import Api from '@/api'
const addressData = require('@/plugins/provinces.json')
import Config from '@/config'

@Options({
  components: {}
})
export default class Register extends SuperVue {
  created() {
    this.getCategoryList()
    // this.getCategoryList2()
    this.getDeptList()
    this.getWorkIndustryList()
    this.devicePixelRatio = window.devicePixelRatio
    const that = this
    window.addEventListener('resize', function () {
      that.devicePixelRatio = window.devicePixelRatio
    })
  }
  // 链接跳转
  toLink(name: string) {
    this.$router.push({ name: name })
  }
  devicePixelRatio = 0
  depts = [] as any;
  codeBtn = {
    disable: false,
    text: '获取验证码'
  };
  categoryList = []; // 行业分类
  workUtilList = []; // 工作单位列表
  selectList = [] as any[];
  stepActive = 0;
  isExpert = false;
  linkFormPageTwo = linkFormPageTwo;
  formPageOne = formPageOne;
  rulePageOne = rulePageOne;
  formPageTwo = formPageTwo;
  rulePageTwo = rulePageTwo;

  checkCode() {
    const postData = {
      phone: this.formPageOne.item2,
      code: this.formPageOne.item3
    }
    Api.http_checkCode(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message); // 接口出错
      } else {
        this.stepActive++;
      }
    });
  }
  // 获取部门列表
  getDeptList() {
    Api.http_queryDeptsList({ offset: 0, length: 999 }).then(res => {
      this.depts = Utils.list2tree(res.data.data, 'id', 'parent_id', 'children')
    })
  }
  // 获取行业分类
  getCategoryList() {
    const postData = {
      offset: 0,
      length: 9999,
      type: 1
    }
    Api.http_getCategoryList(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message); // 接口出错
      } else {
        const data = res.data.data
        // data.forEach(item => { item.label = item.name; item.value = item.name })
        // const tree = Utils.list2tree(data, 'id', 'parent_id', 'children')
        this.categoryList = data
        this.selectList = data
      }
    });
  }
  // 工作单位改变
  unitChange(v) {
    formPageTwo.item37 = v;
    this.getWorkIndustryList();
  }
  // 获取工作单位
  getWorkIndustryList() {
    formPageTwo.item34 = ''
    const postData = {
      offset: 0,
      length: 9999,
      type: [convertUnit.get(formPageTwo.item11)],
    }
    Api.http_industryTableA0(postData).then(res => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.workUtilList = res.data.data
        this.workUtilList.forEach((item: any) => { item.label = item.name; item.value = item.id })
        // console.log('workUtilList :>> ', this.workUtilList);
      }
    })
  }
  // 工作性质
  elSelectV2Change(v) {
    if (typeof v !== 'number') {
      console.log('v :>> ', v);
    }
  }
  // 跳转登录
  toLogin() {
    this.$router.push({ name: 'login' })
  }
  // 注册
  register() {
    if (!Config.REGISTER) return
    const typeIndex = formPageTwo.item33_config.option.findIndex(item => item.value === formPageTwo.item33)
    const result = formPageTwo.item33_config.option[typeIndex].reg.test(formPageTwo.item30)
    if (!result) return this.$message.error('请输入正确的证件号码')
    const postData: any = {}
    function linkToTarget(source, link, target) {
      for (const key in link) {
        target[key] = source[link[key]]
      }
    }
    linkToTarget(this.formPageTwo, this.linkFormPageTwo, postData)
    postData.roles = this.formPageOne.item1
    postData.name = this.formPageTwo.item1.trim() + this.formPageTwo.item2.trim()
    postData.phone = this.formPageOne.item2
    postData.password = this.formPageOne.item4
    postData.code = this.formPageOne.item3
    postData.dept_id = this.formPageTwo.item20[this.formPageTwo.item20.length - 1] ? this.formPageTwo.item20[this.formPageTwo.item20.length - 1] : ''
    // 如果工作单位是选择的，其值为数字，如果是新增的其值为字符串
    if (typeof postData.work_unit === 'number') {
      const work_unit: any = this.workUtilList.find((item: any) => item.id === postData.work_unit);
      if (work_unit) postData.work_unit = work_unit.name;
    }
    for (const key in postData) {
      if (Utils.isObjAndArr(postData[key])) { postData[key] = JSON.stringify(postData[key]) }
    }
    Api.http_userRegister(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message); // 接口出错
      } else {
        this.stepActive++;
        // 如果仅注册为专家则不显示审核中界面
        console.log('postData.roles :>> ', postData.roles);
        if (formPageOne.item1.length === 1 && formPageOne.item1[0] === 3) {
          this.isExpert = true
          setTimeout(() => {
            this.$router.push({ name: 'login' });
          }, 3000)
        }
        this.$message.success(res.message);
      }
    });
  }
  // 发送验证码
  sendCode() {
    if (!Config.REGISTER) return
    const testphone = /^1[3456789]\d{9}$/.test(this.formPageOne.item2)
    if (!testphone) {
      this.$message.error('手机号不正确');
      return
    }
    if (this.codeBtn.disable) return
    const _this = this
    Api.http_sendCode({ phone: this.formPageOne.item2 }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message); // 接口出错
      } else {
        this.$message.success(res.message);
        let timer = 60;
        _this.codeBtn.disable = true
        _this.codeBtn.text = timer + 's';
        const timeStop = setInterval(function () {
          timer--;
          if (timer >= 0) {
            _this.codeBtn.text = timer + 's';
          } else {
            timer = 60;// 当减到0时赋值为60
            _this.codeBtn.text = '获取验证码';
            _this.codeBtn.disable = false
            clearInterval(timeStop);// 清除定时器
          }
        }, 1000)
      }
    });
  }
  // 点击下一步按钮
  nextStepClick() {
    if (!Config.REGISTER) return
    const temp = this.stepActive
    if (temp === 0) {
      this.$refs.formPageOneRef.validate((valid) => {
        if (valid) {
          this.checkCode()
        }
      })
    }
    if (temp === 1) {
      this.$refs['formPageTwoRef'].validate((valid) => {
        if (valid) {
          this.register()
        }
      })
    }
    if (temp === 2) {
      this.toLogin()
    }
  }
}

// 单位性质与单位类型转换表
const convertUnit = new Map([
  [0, 3],
  [1, 4],
  [2, 5],
  [3, 6],
  [4, 7],
  [5, 8],
  [6, 9],
  [7, 10],
  [8, 11],
  [9, 12],
  [10, 13],
  [11, 14],
  [12, 15],
  [13, 16],
])

const validatePassCheck = (rule, value, callback) => {
  if (!Config.REGISTER) return
  if (value === '') {
    return callback(new Error('请再次输入密码!'));
  } else if (value !== formPageOne.item4) {
    return callback(new Error('密码不一致'));
  } else {
    callback();
  }
}

const linkFormPageTwo = {
  last_name: 'item1',
  first_name: 'item2',

  post: 'item4',
  post_rank: 'item5',
  culture: 'item6',
  professional: 'item7',
  linked_industry: 'item8',
  age_limit: 'item9',
  // unit: 'item10',
  nature: 'item11',
  duty: 'item12',
  area: 'item13',
  birthday: 'item14',
  sex: 'item15',
  email: 'item16',
  wechat: 'item17',
  social_position: 'item18',
  self_evaluation: 'item19',
  dept_id: 'item20',
  dept_name_casual: 'item38',
  is_patent: 'item21',
  is_market: 'item22',
  patent_code: 'item23',
  market_code: 'item24',
  id_number: 'item30',
  id_type: 'item33',
  patent_file: 'item31',
  market_file: 'item32',
  work_unit: 'item34',
  profession: 'item35',
  domain: 'item36',
  work_unit_type: 'item37'
}
const formPageOne = {
  item1: [] as any[],
  item1_config: {
    label: '选择身份/角色',
    placeholder: '',
    checkbox: [
      { label: '师生员工', value: 2, disabled: false },
      { label: '咨询专家', value: 3, disabled: false },
      { label: '评价员', value: 74, disabled: false }
    ]
  },
  item2: '',
  item2_config: {
    label: '手机号',
    placeholder: '请输入手机号'
  },
  item3: '',
  item3_config: {
    label: '验证码',
    placeholder: '请输入验证码',
    showPass: false
  },
  item4: '',
  item4_config: {
    label: '密码',
    showPass: false,
    placeholder: '请输入密码'
  },
  item5: '',
  item5_config: {
    label: '确认密码',
    showPass: false,
    placeholder: '请再次输入密码'
  }
};
const rulePageOne = {
  item1: [{ required: true, type: 'array', message: '请至少选择一个身份！', trigger: 'change' }],
  item2: [
    { required: true, message: '请输入手机号！', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
  ],
  // /^\S*(?=\S{8,})(?=\S*\d)(?=\S*[A-z])(?=\S*[!@#$%^&*? ])\S*$/
  item3: [{ required: true, message: '验证码不能为空！', trigger: 'blur' }, { type: 'string', max: 6, message: '验证码最多6字符！', trigger: 'blur' }],
  item4: [{ required: true, message: '请输入密码！', trigger: 'blur' }, { pattern: /^\S*(?=\S*\d)(?=\S*[A-z])(?=\S*[!@#$%^&*? ])\S*$/, message: '必须包含数字,字母和!@#$%^&*?', trigger: 'change' }, { type: 'string', min: 8, message: '密码最少8位', trigger: 'change' }],
  item5: [
    { required: true, message: '请再次输入密码！', trigger: 'blur' },
    { validator: validatePassCheck, trigger: 'blur' }
  ]
};
const formPageTwo = {
  item1: '',
  item1_config: {
    label: '姓',
    placeholder: '请输入姓氏'
  },
  item2: '',
  item2_config: {
    label: '名',
    placeholder: '请输入名字'
  },
  item4: '',
  item4_config: {
    label: '职称',
    placeholder: '请输入职称'
  },
  item5: '',
  item5_config: {
    label: '职称级别',
    placeholder: '请输入职称级别',
    option: [
      { value: '无', label: '无' },
      { value: '初级', label: '初级' },
      { value: '中级', label: '中级' },
      { value: '副高级', label: '副高级' },
      { value: '正高级', label: '正高级' }
    ]
  },
  item6: '',
  item6_config: {
    label: '文化程度',
    placeholder: '请选择文化程度',
    option: [
      { value: 1, label: '博士' },
      { value: 2, label: '硕士' },
      { value: 3, label: '本科' },
      { value: 4, label: '大专' },
      { value: 5, label: '大专以下' }
    ]
  },
  item7: [],
  item7_config: {
    label: '所属行业',
    placeholder: '请选择所属行业'
  },
  item8: [],
  item8_config: {
    label: '关联行业',
    placeholder: '请选择关联行业'
  },
  item9: '',
  item9_config: {
    label: '工作年限（年）',
    placeholder: '请输入工作年限（年）'
  },
  item11: 1,
  item11_config: {
    label: '单位性质',
    placeholder: '请选择单位性质',
    option: [
      { label: '政府单位', value: 0 },
      { label: '高校', value: 1 },
      { label: '科研机构', value: 2 },
      { label: '科技服务机构', value: 3 },
      { label: '医院', value: 4 },
      { label: '社会团体', value: 5 },
      { label: '其他事业单位', value: 6 },
      { label: '军队武警', value: 7 },
      { label: '国企', value: 8 },
      { label: '民企', value: 9 },
      { label: '外企', value: 10 },
      { label: '混合所有制企业', value: 11 },
      { label: '境外机构', value: 12 },
      { label: '其他组织', value: 13 }
    ]
  },
  item12: '',
  item12_config: {
    label: '职务',
    placeholder: '请输入职务'
  },
  item13: [],
  item13_config: {
    label: '所在区域',
    placeholder: '请选择所在区域',
    cascader: addressData.data
  },
  item14: '',
  item14_config: {
    label: '出生年月',
    placeholder: '请选择出生年月'
  },
  item15: null,
  item15_config: {
    label: '性别',
    placeholder: '请选择性别'
  },
  item16: '',
  item16_config: {
    label: '邮箱',
    placeholder: '请输入邮箱'
  },
  item17: '',
  item17_config: {
    label: '微信号',
    placeholder: '请输入微信号'
  },
  item18: '',
  item18_config: {
    label: '社会职务',
    placeholder: '请输入社会职务'
  },
  item19: '',
  item19_config: {
    label: '个人简介',
    placeholder: '请输入个人简介'
  },
  item20: '',
  item20_config: {
    label: '所属部门',
    placeholder: '请选择所属部门'
  },
  item21: '',
  item21_config: {
    label: '是否从事专利分析',
    placeholder: ''
  },
  item22: '',
  item22_config: {
    label: '是否从事市场估值',
    placeholder: ''
  },
  item23: '',
  item23_config: {
    label: '证书编号',
    placeholder: '请输入证书编号'
  },
  item24: '',
  item24_config: {
    label: '证书编号',
    placeholder: '请输入证书编号'
  },
  item30: '',
  item30_config: {
    label: '证件号码',
    placeholder: '请输入证件号码'
  },
  item31: [],
  item31_config: {
    label: '证书附件',
    placeholder: ''
  },
  item32: [],
  item32_config: {
    label: '证书附件',
    placeholder: ''
  },
  item33: 1,
  item33_config: {
    label: '证件类型',
    placeholder: '请选择证件类型',
    option: [
      { label: '居民身份证', value: 1, reg: /^(^\d{18}$|^\d{17}(\d|X|x))$/ },
      { label: '台胞证', value: 2, reg: /^(^\d{8})$/ },
      { label: '港澳通行证', value: 3, reg: /^[a-zA-Z][0-9]{9}$/ },
      { label: '护照', value: 4, reg: /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/ }
    ]
  },
  item34: '', // 工作单位
  item35: '', // 第一专业
  item36: '', // 其他擅长领域
  item37: 4,
  item38: '', // 部门名称
};
const rulePageTwo = {
  item1: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
  item2: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
  // item3: [{ required: true, message: "固定电话不能为空", trigger: "blur" }],
  // item4: [{ required: true, message: "", trigger: "blur" }],
  item5: [{ required: true, message: '职称级别不能为空', trigger: 'blur' }],
  // item6: [{ required: true, message: "", trigger: "blur" }],
  item7: [{ required: true, type: 'array', message: '所属行业不能为空', trigger: 'blur' }],
  // item8: [{ required: true, message: "", trigger: "blur" }],
  // item9: [{ required: true, message: "", trigger: "blur" }],
  item10: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item11: [{ required: true, message: '单位性质不能为空', trigger: 'blur' }],
  item12: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item13: [{ required: true, type: 'array', message: '所在区域不能为空', trigger: 'blur' }],
  item14: [{ required: true, message: '出生年月不能为空', trigger: 'blur' }],
  item15: [{ required: true, type: 'number', message: '不能为空', trigger: 'blur' }],
  item16: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }, { type: 'email', message: '邮箱格式不正确', trigger: 'blur' }],
  item20: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item21: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item22: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item23: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item24: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item33: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
  item30: [
    { required: true, message: '证件号码不能为空', trigger: 'blur' },
    // { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '身份证格式不正确', trigger: 'blur' }
  ]
};
